.create-job-container {
  background: var(--white);
}

.dark-theme .page-container {
  background: var(--dark-page-background) !important;
}
.dark-theme .create-job-container {
  background: var(--dark-input-background) !important;
}

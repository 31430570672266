.table_header {
    background-color: var(--black90);
    height: 52px !important;
}

.table_header th {
    color: var(--white_pure);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: auto;
    padding: 10px 12px !important;
}

tbody.table_body tr {
    background-color: var(--white30) !important
}

.table_body tr:hover {
    background-color: var(--white20) !important;
}
:root {
  --dark-primary-default: #1e96f2;

  --dark-page-background: #383d52;

  --dark-input-label: #cfdaec;
  --dark-input-text: #c0c2c8;
  --dark-input-placeholder: darkgray;
  --dark-input-placeholder-dim: #767884;
  --dark-input-background: #2a2e3f;
  --dark-input-background_dim: #aab0b8;
  --dark-input-border: #484863;
  --dark-input-hover-border: #256a5f;
  --dark-input-error-border: #ed345e;

  --dark-dropdown-option-bg: #3f4252;
  --dark-dropdown-option-text: white;
  --dark-dropdown-option-selected-bg: #5d6379;
  --dark-dropdown-option-hover-bg: #4a4f62;
  --dark-dropdown-option-hover-text: white;
  --dark-dropdown-option-color: #6e7385;

  --dark-richtextarea-toolbar: #bfc8de;
}

/* INPUT */
.dark-theme input:autofill {
  background: var(--dark-input-background);
  -webkit-box-shadow: 0 0 0px 1000px var(--dark-input-background) inset !important;
  box-shadow: 0 0 0px 1000px var(--dark-input-background) inset !important;
}
.dark-theme .input-title {
  color: var(--dark-input-label);
}
.dark-theme .form-control:focus::placeholder {
  opacity: 0;
}
.dark-theme .form-control {
  color: var(--dark-input-text) !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--dark-input-background) inset !important;
  box-shadow: 0 0 0px 1000px var(--dark-input-background) inset !important;
  -webkit-text-fill-color: var(--dark-input-text) !important;
}
.dark-theme .form-control:hover {
  border-color: var(--dark-input-hover-border);
  -webkit-box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  -webkit-text-fill-color: var(--dark-input-text) !important;
}
.dark-theme .form-control:active {
  border-color: var(--dark-input-hover-border);
  -webkit-box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  -webkit-text-fill-color: var(--dark-input-text) !important;
}
.dark-theme .form-control:focus {
  border-color: var(--dark-input-hover-border);
  -webkit-box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  box-shadow: 0 0 0px 1000px var(--dark-input-background) inset,
    0px 0px 3px #24d5b8 !important;
  -webkit-text-fill-color: var(--dark-input-text) !important;
}

/* Richtextarea */
.dark-theme .description-editor {
  border-color: var(--dark-input-border) !important;
  background-color: var(--dark-input-background) !important;
  background-color: var(--dark-input-background) !important;
  color: var(--dark-input-text) !important;
}
.dark-theme .public-DraftEditorPlaceholder-inner {
  color: var(--dark-input-placeholder-dim) !important;
}
.dark-theme .rdw-editor-toolbar {
  background: var(--dark-richtextarea-toolbar) !important;
}
.dark-theme .description-editor:hover {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .description-editor--focus {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .description-editor--focus.description-editor:hover {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .rdw-option-wrapper:hover {
  background-color: var(--dark-input-placeholder-dim) !important;
}
.dark-theme .rdw-option-active {
  background-color: var(--dark-input-placeholder-dim) !important;
}
.dark-theme .rdw-embedded-modal-size-input {
  color: var(--dark-input-text) !important;
}
.dark-theme .rdw-embedded-modal-link-input {
  color: var(--dark-input-text) !important;
}

/* Datepicker */
.dark-theme .react-datepicker__month-container {
  background: var(--dark-dropdown-option-bg);
  border-radius: 5px;
}
.dark-theme .react-datepicker__day--today {
  background: var(--dark-dropdown-option-bg) !important;
}
.dark-theme .react-datepicker__day {
  color: var(--dark-input-text) !important;
  font-weight: 600;
}
.dark-theme .react-datepicker__day:hover {
  background: var(--dark-dropdown-option-hover-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .react-datepicker__day--selected {
  background: var(--dark-primary-default) !important;
  color: white !important;
}

/* DROPDOWN */
.dark-theme .dropdown-container {
  border-color: var(--dark-input-border);
}
.dark-theme .input-placeholder {
  color: var(--dark-input-placeholder-dim) !important;
}
.dark-theme .formselect__control {
  background-color: var(--dark-input-background) !important;
  border: 1px solid var(--dark-input-border) !important;
}
.dark-theme .formselect__single-value {
  color: var(--dark-input-text) !important;
}
.dark-theme .formselect__menu-list {
  background: var(--dark-dropdown-option-bg);
  border: 1px solid var(--dark-input-border);
  border-radius: 5px;
}
.dark-theme .formselect__option {
  color: var(--dark-dropdown-option-text) !important;
}
.dark-theme .formselect__option:hover {
  background: var(--dark-dropdown-option-hover-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .formselect__option--is-focused {
  background: var(--dark-dropdown-option-hover-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .formselect__option--is-selected {
  background: var(--dark-dropdown-option-selected-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .formselect__control:hover {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .formselect__control:active {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .formselect__control:focus {
  border-color: var(--dark-input-hover-border) !important;
}

/* CITY SELECT */
.dark-theme .cityselect__control {
  background-color: var(--dark-input-background) !important;
  border: 1px solid var(--dark-input-border) !important;
}
.dark-theme .cityselect__single-value {
  color: var(--dark-input-text) !important;
}
.dark-theme .cityselect__menu-list {
  background: var(--dark-dropdown-option-bg);
  border: 1px solid var(--dark-input-border);
  border-radius: 5px;
}
.dark-theme .cityselect__option {
  color: var(--dark-dropdown-option-text) !important;
}
.dark-theme .cityselect__option:hover {
  background: var(--dark-dropdown-option-hover-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .cityselect__option--is-focused {
  background: var(--dark-dropdown-option-hover-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .cityselect__option--is-selected {
  background: var(--dark-dropdown-option-selected-bg) !important;
  color: var(--dark-dropdown-option-hover-text) !important;
}
.dark-theme .cityselect__control:hover {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .cityselect__control:active {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .cityselect__control:focus {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .cityselect__input-container {
  color: var(--dark-input-text) !important;
}

/* DROPDOWN WITH CHECKBOX */
.dark-theme .rmsc {
  --rmsc-main: #000;
  --rmsc-bg: var(--dark-dropdown-option-bg);
  --rmsc-selected: var(--dark-dropdown-option-hover-bg);
  --rmsc-hover: var(--dark-input-background);
  --rmsc-gray: #fff;
}
.dark-theme .dropdown-heading-value span {
  color: var(--dark-input-text) !important;
}
.dark-theme .dropdown-heading-value span.gray {
  color: var(--dark-input-placeholder-dim) !important;
}
.dark-theme .select-item.selected {
  color: var(--dark-dropdown-option-hover-text);
  background: var(--dark-dropdown-option-hover-bg) !important;
}
.dark-theme .select-item:hover {
  color: var(--dark-dropdown-option-hover-text);
  background: var(--dark-dropdown-option-hover-bg) !important;
}
.dark-theme .dropdown-container {
  border-color: var(--dark-input-border);
}
.dark-theme .dropdown-container:hover {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .dropdown-container:active {
  border-color: var(--dark-input-hover-border) !important;
}
.dark-theme .dropdown-container:focus {
  border-color: var(--dark-input-hover-border) !important;
}

/* Country selector */
.dark-theme .country-select__list {
  border: 1px solid var(--dark-input-border);
  border-radius: 5px;
}
.dark-theme .country-select__list-item {
  background-color: var(--dark-dropdown-option-bg);
  color: var(--dark-input-text) !important;
}
.dark-theme .country-select__list-item:hover {
  background-color: var(--dark-dropdown-option-hover-bg);
  color: var(--dark-dropdown-option-hover-text) !important;
}

/* City selector */
.dark-theme .city-search {
  border-color: var(--dark-input-border) !important;
}

/* checkbox radio switch */
.dark-theme .custom-control-label {
  color: var(--dark-input-label) !important;
}
.dark-theme .custom-control-label:before {
  border: 1px solid var(--dark-input-border) !important;
}
.dark-theme .custom-switch-description {
  color: var(--dark-input-label) !important;
}
.dark-theme .custom-switch-indicator:before {
  top: 1px !important;
}
.dark-theme .custom-control-label::before {
  background-color: #131b2d;
  border-color: #4a5677 !important;
}

.dark-theme .input-group-text {
  background-color: var(--dark-dropdown-option-hover-bg);
}
.dark-theme .flagIcon {
  border-color: var(--dark-input-border) !important;
  background-color: var(--dark-input-background) !important;
}
.dark-theme .eyeIcon {
  border-color: var(--dark-input-border) !important;
  background-color: var(--dark-input-background) !important;
}
.dark-theme .currency-selector-select .formselect__control {
  background-color: var(--dark-input-background_dim);
}
.dark-theme .input-field-icon {
  border-color: var(--dark-input-border) !important;
  background-color: var(--dark-primary-default) !important;
}
.dark-theme .input-field-icon-error {
  border-color: var(--dark-input-error-border) !important;
}

/*RICHTEXT AREA POPOVER*/
.dark-theme .popover-head-primary .popover-header {
  background: var(--primary-info-default) !important;
}

/*QUESTION CONTAINER*/
.dark-theme .question-container {
  background: var(--dark-page-background) !important;
}
.dark-theme .city-search .formselect__input-container {
  color: var(--dark-input-text) !important;
}
